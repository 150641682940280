<template>
<div>
    <v-dialog v-model="dialog.personal_lista" persistent max-width="450" :transition="DialogAnimation" scrollable :overlay-opacity="TemaDark?'.5':'.25'" overlay-color="black">
        <v-fade-transition>
            <v-card class="rounded-lg" v-show="!dialog.usuario">
                <v-card-title class="pa-0 modal-title">
                    <v-toolbar flat class="rounded-t-lg" height="56">
                        <v-toolbar-title class="pa-0 text-capitalize" style="line-height: 1.1;">
                            <v-list-item class="pl-0 rounded-t-lg">
                                <v-list-item-avatar class="mr-2 rounded-lg" :color="TemaDark?'primary':'secondary'" tile width="8" height="30" min-width="8"/>
                                <v-list-item-content class="py-0">
                                    <v-list-item-title class="headline pb-0 mb-0">
                                        <v-toolbar-title class="pa-0 trenos-title-toolbar text-capitalize" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'" style="line-height: 1.1;">Personal {{lista.nombre}}</v-toolbar-title>
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="font-weight-medium">
                                        <span class="text-capitalize">Lista</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <!--<v-btn icon @click="CerrarLista()" class="mr-0">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>-->
                    </v-toolbar>
                </v-card-title>
                <v-card flat max-height="70vh" class="scroll-card">
                    <v-container class="pb-0 py-0">
                        <v-row class="px-0">
                            <v-col cols="12" class="" v-if="lista.datos && lista.datos.length">
                                <v-list class="rounded-lg">
                                    <v-list-item flat v-for="usuario in lista.datos" :key="usuario.id" class="rounded-lg pr-3 pl-2" @click="GetUsuario(usuario.id)">
                                        <v-list-item-avatar class="my-1 mr-2" size="30">
                                            <v-avatar :color="TemaDark?'grey darken-3':'grey lighten-4'" size="30">
                                                <v-icon size="15" :color="TemaDark?'grey lighten-4 ':'grey'">mdi-account-circle-outline</v-icon>
                                            </v-avatar>
                                        </v-list-item-avatar>
                                        <v-list-item-content class="py-0">
                                            <v-list-item-title class="text-subtitle-2 text-capitalize" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">{{usuario.nombre}} {{usuario.apellido}}</v-list-item-title>
                                            <v-list-item-subtitle class="text-capitalize">
                                                {{usuario.tipo}}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="CerrarLista()">
                        Cerrar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-fade-transition>
    </v-dialog>
    <v-dialog v-model="dialog.usuario" persistent max-width="450" :transition="DialogAnimation" overlay-opacity=".25">
        <v-card flat class="rounded-lg" v-show="!DialogTurno">
            <v-toolbar flat height="62" class="rounded-t-lg">
                <v-list-item class="px-0">
                <v-list-item-avatar class="mr-2 rounded-lg" :color="TemaDark?'primary':'secondary'" tile width="8" height="30" min-width="8"/>
                    <v-list-item-content>
                        <v-list-item-title class="headline mb-0">
                            <v-toolbar-title class="pa-0 text-capitalize trenos-title-toolbar" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'" style="line-height: 1.1;">Informacion</v-toolbar-title>
                        </v-list-item-title>
                        <v-list-item-subtitle class="font-weight-medium mb-0">
                            <span class="text-capitalize">Usuario</span>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-spacer></v-spacer>
                <!--<v-btn icon @click="CloseDialogUsuario()" class="mr-0">
                    <v-icon>mdi-close</v-icon>
                </v-btn>-->
            </v-toolbar>
            <v-tabs centered mobile-breakpoint="400" v-model="vista">
                <v-tabs-slider color=primary></v-tabs-slider>
                <v-tab class="rounded-lg">Calendario</v-tab>
                <v-tab class="rounded-lg">Informacion</v-tab>
            </v-tabs>
            <v-card flat min-height="45vh" style="overflow: auto;">
                <v-tabs-items v-model="vista">
                    <v-tab-item eager>
                        <v-card class="pa-2 mx-0 mt-0" flat>
                            <Calendario :usuario_id="usuario_id" />
                        </v-card>
                    </v-tab-item>
                    <v-tab-item eager>
                        <v-card flat min-height="350">
                            <v-fade-transition mode="out-in" hide-on-leave>
                                <v-card flat class="mx-5 rounded-lg" v-if="!usuario_edit_vista">
                                    <v-card flat class="text-center py-2">
                                        <v-list-item-avatar size="80" class="mx-auto">
                                            <v-avatar color="grey lighten-4" size="80">
                                                <v-icon size="50" color="grey">mdi-account-circle-outline</v-icon>
                                            </v-avatar>
                                        </v-list-item-avatar>
                                        <v-list-item-subtitle class="font-weight-medium mb-0" v-if="edit">
                                            <v-btn small text color="primary" class="secondary rounded-xl" @click="usuario_edit_vista = true">
                                                <v-icon size="18" left>mdi-account-edit</v-icon> Editar
                                            </v-btn>
                                        </v-list-item-subtitle>
                                        <v-toolbar-title class="pa-0 text-capitalize trenos-title-toolbar" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'" style="line-height: 1.1;">
                                            {{ datos_usuario.nombre }} {{ datos_usuario.apellido }}
                                        </v-toolbar-title>
                                        <v-list-item-subtitle class="font-weight-medium mb-0">
                                            <span class="text-capitalize grey--text text--darken-1">{{TurnoTipo}}</span>
                                        </v-list-item-subtitle>
                                    </v-card>
                                    <v-card outlined class="rounded-lg">
                                        <v-list class="px-1 py-0">
                                            <v-list-item class="px-1">
                                                <v-list-item-avatar class="my-1 mr-1">
                                                    <v-avatar :color="TemaDark?'grey darken-3':'grey lighten-4'" size="33">
                                                        <v-icon size="15" :color="TemaDark?'grey lighten-4 ':'grey'">mdi-email-outline</v-icon>
                                                    </v-avatar>
                                                </v-list-item-avatar>
                                                <v-list-item-content class="py-0">
                                                    <v-list-item-title class="text-subtitle-2" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">{{ FormatCorreo }}</v-list-item-title>
                                                    <v-list-item-subtitle class="text-capitalize">Correo</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item :href="`tel:${FormatContacto}`" class="px-1">
                                                <v-list-item-avatar class="my-1 mr-1">
                                                    <v-avatar :color="TemaDark?'grey darken-3':'grey lighten-4'" size="33">
                                                        <v-icon size="15" :color="TemaDark?'grey lighten-4 ':'grey'">mdi-phone</v-icon>
                                                    </v-avatar>
                                                </v-list-item-avatar>
                                                <v-list-item-content class="py-0">
                                                    <v-list-item-title class="text-subtitle-2 text-capitalize" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">{{ FormatContacto }}</v-list-item-title>
                                                    <v-list-item-subtitle>Contacto</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item class="px-1">
                                                <v-list-item-avatar class="my-1 mr-1">
                                                    <v-avatar :color="TemaDark?'grey darken-3':'grey lighten-4'" size="33">
                                                        <v-icon size="15" :color="TemaDark?'grey lighten-4 ':'grey'">mdi-home-outline</v-icon>
                                                    </v-avatar>
                                                </v-list-item-avatar>
                                                <v-list-item-content class="py-0">
                                                    <v-list-item-title class="text-subtitle-2" :class="TemaDark?'grey--text text--lighten-3':'grey--text text--darken-1'">{{ Residencia }}</v-list-item-title>
                                                    <v-list-item-subtitle>Residencia</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-card>
                                </v-card>
                            </v-fade-transition>
                            <v-fade-transition mode="out-in" hide-on-leave>
                                <v-card outlined class="mx-5 mb-4 pb-2 mt-2 rounded-lg" v-if="usuario_edit_vista">
                                    <v-toolbar flat class="rounded-t-lg" height="56">
                                            <v-list-item class="px-0 rounded-t-lg">
                                                <v-list-item-avatar class="mr-2 rounded-lg"  >
                                                    <v-avatar :color="TemaDark?'grey darken-3':'secondary'" size="33">
                                                        <v-icon size="20" :color="TemaDark?'grey lighten-4 ':'primary'">mdi-account-circle-outline</v-icon>
                                                    </v-avatar>
                                                </v-list-item-avatar>
                                                <v-list-item-content class="py-0">
                                                    <v-list-item-title class="headline pb-0 mb-0">
                                                        <v-toolbar-title class="pa-0 text-capitalize grey--text text--darken-2 trenos-title-toolbar" style="line-height: 1.1;">{{datos_usuario.nombre}} {{datos_usuario.apellido}}</v-toolbar-title>
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle class="font-weight-medium">
                                                        <span>Edicion</span>
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-action class="my-0">
                                                    <v-menu transition="scale-transition" rounded="lg" origin="right top" left>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn icon v-bind="attrs" v-on="on">
                                                        <v-icon color="primary" size="20">mdi-dots-vertical</v-icon>
                                                    </v-btn>
                                                    </template>
                                                    <v-list dense>
                                                        <v-list-item class="px-3" @click="ResetPass(usuario_id, datos_usuario.correo)">
                                                            <v-list-item-avatar class="mr-2" size="35" color="secondary">
                                                                <v-icon size="20" color="primary">mdi-lock-reset</v-icon>
                                                            </v-list-item-avatar>
                                                            <v-list-item-content>
                                                                <v-list-item-title>Reiniciar contraseña</v-list-item-title>
                                                            </v-list-item-content>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                                    
                                                </v-list-item-action>
                                            </v-list-item>
                                    </v-toolbar>
                                    <br>
                                    <PerfilEdit :datos="datos_usuario" vista="buscar" :usuario_id="usuario_id" />
                                    <!--<v-card flat class="text-center">
                                        <v-btn text color="primary" class="mx-auto" @click="usuario_edit_vista = false">
                                            Volver
                                        </v-btn>
                                    </v-card>-->
                                </v-card>
                            </v-fade-transition>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="CloseDialogUsuario()">
                    Cerrar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import {
    mapState,
    mapMutations,
} from "vuex";
export default {
    name: 'PersonalLista',
    components: {
        Calendario: () => import("@/components/Calendario"),
        PerfilEdit: () => import('@/components/PerfilEdit')
    },
    props: ['lista', 'usuario_id_dialog','edit'],
    data() {
        return {
            usuario_id: null,
            panel_usuario: false,
            datos_usuario: {},
            vista: null,
            usuario_edit_vista: false,
            usuario_menu: false
        }
    },
    computed: {
        ...mapState(['dialog', 'usuario','sistema']),
        FormatContacto() {
            let numero = "Sin Informacion";
            if (this.datos_usuario.telefono)
                numero = "+569" + this.datos_usuario.telefono;
            return numero;
        },
        FormatCorreo() {
            let correo = "Sin Informacion";
            if (this.datos_usuario.correo) correo = this.datos_usuario.correo;
            return correo;
        },
        TurnoTipo() {
            let turno_tipo = 'Sin informacion'
            if (this.datos_usuario.tipo) {
                switch (this.datos_usuario.tipo) {
                    case 'ft':
                        turno_tipo = 'Full Time'
                        break;
                    case 'pt':
                        turno_tipo = 'Part Time 80'
                        break;
                    case 'pts':
                        turno_tipo = 'Part Time 120'
                        break;
                    case 'il':
                        turno_tipo = 'Inspector de Línea'
                        break;
                    case 'adm':
                        turno_tipo = 'Administrador'
                        break;
                    case 'ctc':
                        turno_tipo = 'Controlador Trtáfico'
                        break;
                    case 'etl':
                        turno_tipo = 'Encargado Terminal'
                        break;

                }
            }
            return turno_tipo
        },
        Residencia() {
            let residencia = 'Sin informacion'
            if (this.datos_usuario.residencia) {
                switch (this.datos_usuario.residencia) {
                    case 11:
                        residencia = 'Valparaíso'
                        break;
                    case 12:
                        residencia = 'Viña del Mar'
                        break;
                    case 13:
                        residencia = 'Concon'
                        break;
                    case 14:
                        residencia = 'Casablanca'
                        break;
                    case 15:
                        residencia = 'Santiago'
                        break;
                    case 21:
                        residencia = 'Quilpué'
                        break;
                    case 22:
                        residencia = 'Villa Alemana'
                        break;
                    case 31:
                        residencia = 'Limache'
                        break;
                    case 32:
                        residencia = 'Olmué'
                        break;
                    case 33:
                        residencia = 'Quillota'
                        break;
                }

            }
            return residencia
        },
        DialogUsuario() {
            return this.dialog.usuario
        },
        DialogTurno(){
            return this.dialog.turno
        },
        TemaDark(){
            let dark = false
            if(this.sistema.tema == 'dark' || (this.sistema.tema == 'system' && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches)) dark = true
            return dark
        },
        DialogAnimation(){
            let animation = 'fade-transition'
            if(!this.$vuetify.breakpoint.mdAndUp) animation = 'dialog-bottom-transition'
            return animation
        }
    },
    methods: {
        ...mapMutations(["ShowSnackBar", "ErrorRequest", 'CloseDialog', 'OpenDialog']),
        //Obtiene los datos del usuario consultado con base en su id.
        async GetUsuario(usuario_id) {
            this.OpenDialog('usuario')
            this.usuario_id = usuario_id;
            await this.axios
                .get(`/buscar/usuario/${usuario_id}`, {
                    headers: {
                        token: this.usuario.token,
                    },
                })
                .then((respuesta) => {
                    if (respuesta.data.mensaje) {
                        this.ErrorRequest(respuesta.data.mensaje)
                    } else {
                        this.datos_usuario = respuesta.data
                    }
                })
                .catch((err) => {
                    this.overlay = false
                    this.ErrorRequest(err)
                });
        },
        async ResetPass(id, correo) {
            let datos = {
                id,
                correo
            }
            await this.axios.post(`/buscar/usuario/resetpass`, datos, {
                headers: {
                    token: this.usuario.token
                }
            }).then(respuesta => {
                if (respuesta.data.mensaje) {
                    this.ErrorRequest(respuesta.data.mensaje)
                }
            }).catch(err => {
                this.ErrorRequest(err)
            })
        },
        //Cierra el dialog personal_lista
        CerrarLista() {
            this.CloseDialog('personal_lista')
        },
        //Cierra el dialog de la informacion del usuario consultado.
        CloseDialogUsuario() {
            this.CloseDialog('usuario')
            this.panel_usuario = null
        },
    },
    watch: {
        usuario_id_dialog: function (val) {
            if (val) this.GetUsuario(val)
        },
        DialogUsuario: function (val) {
            if (val) this.usuario_edit_vista = false
        }
    }
}
</script>

<style scoped>
.scroll-card{
    will-change: transform !important;
    scroll-behavior: smooth;
    overflow: auto
}
</style>
